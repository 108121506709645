import { LeagueDivision } from '@/GeneratedTypes/LeagueDivision'

export function setFieldsForNewDivision(division: LeagueDivision) {
  if (!division.divisionID) {
    division.divisionName = 'All'
    division.gameCount = 10
    division.practiceLength = 90
    division.gameLength = 90
    division.gender = 'E'
  }
}
